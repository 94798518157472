
import {defineComponent, onMounted} from "vue";
import Typed from 'typed.js';

export default defineComponent({
  name: "Home",
  setup () {


    onMounted(() => {
      const typed = new Typed('#typing', {
        strings: ['This website is under construction ^800 `🚧`', 'It will be ready soon! ^800 `🙂`'],
        typeSpeed: 75,
        backSpeed: 15,
        autoInsertCss: true,
      });

      typed.start()
    })

    return {

    }
  },
});
